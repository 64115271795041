import React from 'react'
import Layout from '../Layout'
import View from '../View'
import {
  getCampaignLogsScheduled,
  getCampaignLogById,
  deleteCampaignLogById } from '../../utils/email'
import { Table } from '../Table'
// import 'react-table/react-table.css'
import Moment from 'moment'
import { Link } from 'gatsby'
import WaitLoader from '../WaitLoader'
import { navigate } from '@reach/router'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material'

class EmailCampaignLog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      logs: [],
      errorMessage: '',
      loading: true,
      confirmDeleteOpen: false,
      campaignLog: null
    }
  }

  componentDidMount() {
    getCampaignLogsScheduled(function(err, response){
      if(err){
        console.log(err)
        this.setState({errorMessage: err.message, loading: false, logs: []})
      }else{
        console.log(response)
        if(!response){
          response = []
        }
        this.setState({ logs: response, loading: false })
      }
    }.bind(this))
  }

  confirmDelete = (logId) => {
    getCampaignLogById(logId, function(err, response){
      if(err){
        this.setState({errorMessage: err.message})
      }
      if(response){
        this.setState({
          campaignLog: response,
          confirmDeleteOpen: true
        })
      }else{
        this.setState({errorMessage: 'Log not found'})
      }
    }.bind(this));
  }

  onDelete = () => {
    this.setState({
      loading: true
    }, function(){
      console.log(this.state.campaignLog._id)
      deleteCampaignLogById(this.state.campaignLog._id, function(err, data){
        console.log(err)
        console.log(data)
        getCampaignLogsScheduled(function(err, response){
          if(err){
            console.log(err)
            this.setState({
              errorMessage: err.message,
              confirmDeleteOpen: false,
              loading: false
            })
          }else{
            console.log(response)
            if(!response){
              response = []
            }
            this.setState({
              logs: response,
              confirmDeleteOpen: false,
              loading: false
             })
          }
        }.bind(this))
      }.bind(this))
    }.bind(this))
  }

  render() {
    const logColumns = [
      {
        id: 'scheduledDate',
        Header: 'Scheduled Date',
        accessor: d => {
          return Moment(d.scheduled)
          .local()
          .format("MM-DD-YYYY hh:mm:ss a")
        }
      },
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Subject',
        accessor: 'subject'
      },
      {
        id: 'actions',
        Header: 'Actions',
        accessor: d => d._id,
        Cell: props => <div>
          <div>
            <Link className="button" to={'/email/campaign/?log=' + props.value}>
            Edit Campaign
            </Link>
          </div>
          <div>
            <button className="button" to={'/email/campaign/log/' + props.value}
            onClick={() => this.confirmDelete(props.value)}>
            Delete Campaign
            </button>
          </div>
        </div>
      }
    ]
    let errorMessage = ''
    if(this.state.errorMessage){
      errorMessage = <p className="error">Error: {this.state.errorMessage}</p>
    }
    let confirmDeleteOpen = this.state.confirmDeleteOpen
    return (
      <Layout>
      <View title="SCHEDULED EMAIL CAMPAIGNS">
      <div style={{
        textAlign: 'right'
      }}>
      <button style={{
        background: '#27AE60'
      }} onClick={
        (e) => navigate('/email/campaign')
      }>SCHEDULE A NEW CAMPAIGN</button>
      </div>
      <div className="row col-xs-12">
      <p>Manage your email campaigns, starting with 'Schedule A New Campaign' if you plan to create a new one. Once scheduled, your email campaigns will appear in the list below with the following actions: 'View Campaign', 'Edit Campaign', and 'Delete Campaign.'</p>
      </div>
      <div className="row center-xs">
        <div className="email-form col-xs-12 col-sm-12 col-md-12 relative">
          <WaitLoader loading={this.state.loading} />
          {errorMessage}
          <Table
            data={this.state.logs}
            columns={logColumns}
            defaultPageSize={10}
            minRows={3}
          />
        </div>
      </div>
      </View>
      <Dialog
        className="standard-dialog"
        open={confirmDeleteOpen}
        onClose={() => this.setState({ confirmDeleteOpen: false})}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete Campaign: {this.state.campaignLog?.name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this campaign? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="underline-button" onClick={() => this.setState({ confirmDeleteOpen: false})}>Cancel</Button>
          <Button variant="contained" onClick={this.onDelete}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      </Layout>
    )
  }
}

export default EmailCampaignLog
