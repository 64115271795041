import React from 'react'
import PropTypes from 'prop-types'
import { isLoggedIn, isFSUser } from '../utils/auth'
import { isBrowser } from '../utils/misc'
import { Router } from '@reach/router'
import Amplify, { Auth } from 'aws-amplify'
import Login from './Login'
import {
  ConfirmSignIn,
  ConfirmSignUp,
  ForgotPassword,
  RequireNewPassword,
  SignUp,
  VerifyContact,
  withAuthenticator } from 'aws-amplify-react'

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: process.env.GATSBY_COGNITO_REGION,
    userPoolId: process.env.GATSBY_USER_POOL_ID,
    identityPoolId: process.env.GATSBY_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.GATSBY_APP_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  }
})
Auth.configure({
  Auth: {
    mandatorySignIn: false,
    region: process.env.GATSBY_COGNITO_REGION,
    userPoolId: process.env.GATSBY_USER_POOL_ID,
    identityPoolId: process.env.GATSBY_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.GATSBY_APP_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  }
})

const FSRoute = ({ component: Component, ...rest }) => {
  if (!isLoggedIn() && isBrowser) {
    // If we’re not logged in, redirect to the home page.
    // navigate(`/login`)
    window.location.href = '/login'
    return null
  }else{
    if(isLoggedIn()){
      if(!isFSUser() && !isBrowser){
        window.location.href = '/'
        return null
      }
    }
  }
  return (
    <Router>
      <Component {...rest} />
    </Router>
  )
}

FSRoute.propTypes = {
  component: PropTypes.any.isRequired,
}

export default withAuthenticator(FSRoute, false, [
  <Login/>,
  <ConfirmSignIn/>,
  <VerifyContact/>,
  <SignUp/>,
  <ConfirmSignUp/>,
  <ForgotPassword/>,
  <RequireNewPassword />
])
