import React from 'react'
import CampaignLog from '../../../components/Email/CampaignLogsScheduled'
import FSRoute from '../../../components/FSRoute'
import { isBrowser } from '../../../utils/misc'
const App = ({ data }) => {
  if (!isBrowser) return null
  return (
    <>
      <FSRoute
        path="/email/campaign/scheduled"
        component={CampaignLog}
        fsOnly="false"
        data={data}
      />
    </>
  )
}

export default App
